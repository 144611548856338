/* .root {
  flex-grow: 1;
} */
.header-title {
  text-align: right;
  font-size: 1.2rem;
}

.header-title-align {
  text-align: right;
}

.logo-container {
  display: flex;
  align-items: flex-start;
  height: 90px;
  /* padding: 0.5rem 0; */
}

.logo {
  height: 100%;
  object-fit: contain;
}

@media (max-width: 600px) {
  .logo-container {
    justify-content: center;
    height: 65px;
  }

  .header-title {
    padding-bottom: 0.5rem;
    text-align: center;
    font-size: 0.85rem;
  }

  .header-title-align {
    text-align: center;
  }
}

@media (max-width: 320px) {
  .logo-container {
    height: 50px;
  }

  .header-title {
    font-size: 0.7rem;
  }
}
