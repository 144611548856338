.main403 {
  position: absolute;
  margin: 0;
  padding: 0;
  /* padding-top: 15%; */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background-image: url(../../static/bg-403.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
}

.main404 {
  position: absolute;
  margin: 0;
  padding: 0;
  /* padding-top: 15%; */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background-image: url(../../static/stream_upcoming.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
}

.main404upper {
  position: absolute;
  margin: 0;
  padding: 0;
  /* padding-top: 15%; */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAG0lEQVQYV2NkYGD4z8DAwMgABXAGNgGwSgwVAFbmAgXQdISfAAAAAElFTkSuQmCC)
    repeat;
}

.main404upper2 {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  /* padding-top: 15%; */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.main404h1 {
  font-size: 8em;
}

.main404h2 {
  font-size: 2em;
}

.main404h3 {
  font-size: 1em;
}
