.wowzaPlayerContainer {
  width: 100%;
  /* height: 100%; */
}

.wowzaPlayerSpinner {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  background-color: #fff;
}

.wowzaPlayerSpinner > div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.wowzaPlayerSpinner .MuiCircularProgress-colorPrimary {
  color: var(--clr-g-3);
}

.wowzaPlayer {
  width: '100%';
  height: 0;
  padding: '0 0 56.25% 0';
}

.playerLoad {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
