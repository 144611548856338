.Toastify__progress-bar {
  /* background: var(--clr-g-3) !important; */
  border-radius: 0 4px 4px 4px;
}

.Toastify__toast {
  overflow: visible;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.2),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.1),
    0 16px 16px rgba(0, 0, 0, 0.05);
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 95vw;
    padding: 0;
    /* left: 0; */
    margin: 10px auto;
  }
  .Toastify__toast {
    margin-bottom: 5px;
  }
}
