.presenterImgContainer {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.presenterImg {
  height: 305px;
  width: 305px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15),
    0 16px 16px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.imgWithChapters {
  height: 280px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-top: 1.5rem;
  background-color: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15),
    0 16px 16px rgba(0, 0, 0, 0.15);
}

.presenterImage img,
.presenterImg img {
  object-fit: cover;
}

/* /////////////////////////////
    Media Queries
///////////////////////////// */
@media (min-width: 1400px) and (max-width: 1680px) {
  .presenterImg,
  .imgWithChapters {
    height: 250px;
    max-width: 250px;
  }
}

@media (min-width: 1280px) and (max-width: 1399px) {
  .presenterImg,
  .imgWithChapters {
    height: 180px;
    max-width: 180px;
    margin-top: 0.5rem;
  }
}

@media (min-width: 1133px) and (max-width: 1279px) {
  .presenterImg {
    height: 180px;
    width: 180px;
    margin-top: 1rem;
  }

  .imgWithChapters {
    height: 130px;
    max-width: 130px;
  }
}

@media (min-width: 1024px) and (max-width: 1132px) {
  .presenterImgContainer {
    margin-top: 0.3rem;
    margin-bottom: 0.7rem;
  }

  .presenterImg {
    height: 180px;
    width: 180px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .imgWithChapters {
    height: 110px;
    max-width: 110px;
  }
}

@media (min-width: 960px) and (max-width: 1023px) {
  .presenterImgContainer {
    margin: 1rem;
  }

  .presenterImg,
  .imgWithChapters {
    height: 220px;
    max-width: 220px;
  }
}

@media (max-width: 959px) {
  .presenterImgContainer {
    margin: 1rem;
  }

  .presenterImg,
  .imgWithChapters {
    height: 220px;
    max-width: 220px;
  }
}

@media (max-width: 600px) {
  .presenterImgContainer {
    margin-top: 0.5rem;
    padding: 0;
  }
}

@media (max-width: 440px) {
  .presenterImgContainer {
    margin-top: 0.5rem;
  }

  .presenterImg,
  .imgWithChapters {
    height: 160px;
    max-width: 160px;
  }
}
