.only-video-content {
  background-color: #fff;
  backdrop-filter: blur(5px);
}

.only-video-content > h5 {
  font-size: clamp(1rem, calc(0.33rem + 1.28vw), 1.5625rem);
}

/* Correspond to Material UI SM */
@media (max-width: 959px) {
  .only-video-content {
    width: 99%;
    max-width: 99%;
    margin: 0 auto;
  }

  p {
    font-size: 0.75rem !important;
  }
}
