.MuiFormLabel-root.Mui-focused {
  color: #959595 !important;
}

.question-title.MuiTypography-h4 {
  font-size: 2rem;
}

.question-title.MuiTypography-h4,
.question-subtitle.MuiTypography-subtitle1 {
  text-align: left;
}

@media (min-width: 960px) and (max-width: 1028px) {
  .question-title.MuiTypography-h4 {
    font-size: 1.8rem;
  }

  .question-title.MuiTypography-h4,
  .question-subtitle.MuiTypography-subtitle1 {
    text-align: left;
  }
}

@media (min-width: 600px) and (max-width: 690px) {
  .question-title.MuiTypography-h4 {
    font-size: 1.8rem;
  }

  .question-title.MuiTypography-h4,
  .question-subtitle.MuiTypography-subtitle1 {
    text-align: center;
  }
}

@media (max-width: 552px) {
  .question-title.MuiTypography-h4 {
    font-size: 1.6rem;
  }

  .question-title.MuiTypography-h4,
  .question-subtitle.MuiTypography-subtitle1 {
    text-align: center;
  }
}

@media (max-width: 433px) {
  .question-title.MuiTypography-h4 {
    margin-top: 0 !important;
    padding: 0 0 12px !important;
    font-size: 1.6rem;
    text-align: center;
  }

  .question-subtitle.MuiTypography-subtitle1 {
    font-size: 12px !important;
    text-align: center;
  }

  .MuiFormControl-root label {
    font-size: 0.7rem;
  }

  .input-text {
    margin-top: 0 !important;
  }
}
