.root {
  display: flex;
}

.presenterItemInVideoPlayer {
  height: 100%;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  background-color: #fff !important;
}

.presenterItemContainerInAudioPlayer {
  height: 100%;
  width: 100%;
  background-color: #f7f7f7;
}

.presenterItemInAudioPlayer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  box-shadow: none !important;
  border-radius: 0px !important;
  background: transparent !important;
  animation: fadein 1s;
}

.presenterItemInAudioPlayer h3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.8rem 0.8rem;
  font-size: 1.2rem;
  text-align: center;
  color: var(--clr-g-3);
}

.presenterItemInAudioPlayer h3 span {
  color: var(--clr-g-7e);
  font-size: 0.85rem;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* /////////////////////////////
    Media Queries
///////////////////////////// */
@media (min-width: 600px) and (max-width: 1023px) {
  .presenterItemInAudioPlayer {
    flex-direction: row;
    align-items: flex-end;
  }

  .presenterItemInAudioPlayer div:nth-child(2) {
    padding: 0.7rem 2.1rem 1rem 0;
  }
  .presenterItemInAudioPlayer div:nth-child(2) > * {
    text-align: left;
  }
}
