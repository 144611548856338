.btn-container::-webkit-scrollbar-track {
  border-radius: 0.45rem;
  background-color: var(--clr-g-c6);
}

.btn-container::-webkit-scrollbar {
  width: 0.45rem;
  border-radius: 0.125rem;
}

.btn-container::-webkit-scrollbar-thumb {
  border-radius: 0.45rem;
  background-color: var(--clr-g-7e);
}
