.chapters-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-height: 100%;
  min-height: 60px;
  height: 100%;
  padding: 1rem;
  background-color: #fff;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #333 !important;
  border-width: 2px;
}

@media (max-width: 959px) {
  .chapters-container {
    padding: 1rem 0;
  }
}

@media (max-width: 413px) {
  .MuiPopover-paper {
    left: 8px !important;
  }
}
