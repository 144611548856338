#bg {
  position: fixed;
  z-index: -1000;
}

.bg {
  height: 100vh;
  width: 100vw;
}

.bg span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.bg-img {
  object-fit: cover;
}
