.policy-container {
  margin-top: 1rem;
  margin-bottom: 3rem;
  max-width: 96ch !important;
}

.policy-title {
  margin-top: 8px !important;
}

.policy-container a {
  text-decoration: underline;
  color: var(--clr-brr);
}

.policy-container h6 {
  font-weight: 600;
  color: var(--clr-g-3);
}

.policy-container li {
  align-items: flex-start;
}

.policy-container li div:first-child {
  margin-top: 0.7rem;
  min-width: 25px;
}

.policy-container svg {
  font-size: 0.6rem;
  color: var(--clr-g-3);
}

.pt-0 {
  padding-top: 0 !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.capitalized {
  text-transform: capitalize;
}

@media (max-width: 959px) {
  .policy-container {
    max-width: 75ch !important;
  }

  .policy-container li div:first-child {
    margin-top: 0.5rem;
    min-width: 20px;
  }

  .policy-container span {
    font-size: 0.75rem !important;
  }
}

@media (max-width: 400px) {
  .policy-title {
    margin: 0 auto !important;
    margin-top: 0 !important;
  }

  .policy-container h6 {
    line-height: 1.3;
  }

  .policy-container svg {
    font-size: 0.5rem;
  }

  .policy-container li div:first-child {
    margin-top: 0.6rem;
    min-width: 15px;
  }

  .policy-title h4 {
    font-size: 1.8rem;
  }
}
