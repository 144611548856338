.MuiDialog-paperWidthSm {
  min-width: 500px !important;
}

.registration-title {
  text-align: center;
  font-weight: 600;
}

.footer__content {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-right: 7px;
  padding-left: 0;
}

.footer__content div {
  display: flex;
  align-items: center;
}

.footer__content div:nth-child(2) > p > span {
  font-weight: 700;
}

.footer__content p,
.footer__content a {
  margin-top: 1px !important;
  transition: color 0.25s;
}

.footer__content a:hover {
  color: var(--clr-g-95);
}

.footer__content .Mui-checked {
  color: #333 !important;
}

.btn-login {
  min-width: 86px !important;
}

.disclaimer-content {
  position: relative;
  /* height: 250px; */
  height: auto;
  margin: 2rem 24px 1rem;
  background-color: #e0e0e0;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.disclaimer-content > h3 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 0.25rem 0.7rem;
  color: var(--clr-g-3);
  background-color: var(--clr-g-c6);
  opacity: 0.5;
}

.disclaimer-content > div {
  margin-top: 2.5rem;
}

.select-in-registration-container {
  /* margin-top: 1.5rem; */
  padding: 1rem 0;
}

.select-mt {
  margin-top: 0.5rem;
}

.select-in-registration em {
  color: var(--clr-g-7e);
}

.select-in-registration .MuiInput-underline:before,
.select-in-registration .MuiInput-underline:after {
  border-bottom-width: 2px;
}

.select-in-registration .MuiInput-underline:after {
  border-color: var(--clr-g-7e);
}

@media (max-width: 600px) {
  .MuiDialog-paperWidthSm {
    min-width: 300px !important;
  }

  .footer {
    flex-direction: column-reverse;
    margin-top: 1.6rem;
  }

  .footer__content {
    padding-left: 0;
    margin-right: 0;
    align-items: flex-start;
  }

  .footer button {
    width: 100% !important;
    margin-bottom: 1.3rem;
    margin-left: 0 !important;
  }
}

@media (max-width: 480px) {
  .registration-title,
  .content-text {
    text-align: center;
  }

  .email-input {
    margin: 1.5rem 0 !important;
  }

  .footer__content {
    gap: 0.75rem;
  }

  .footer__content div {
    align-items: flex-start;
    gap: 0.3rem;
  }

  .footer__content p {
    margin-right: 0 !important;
    line-height: 1.25 !important;
  }
}

@media (max-width: 340px) {
  .MuiDialog-paperWidthSm {
    min-width: 0 !important;
  }

  .registration-title,
  .email-input {
    padding: 12px !important;
  }

  .footer__content p,
  .footer__content a {
    line-height: 1.25 !important;
  }
}

@media (max-width: 320px) {
  .registration-title {
    margin-bottom: 0 !important;
  }
  .registration-title h2 {
    line-height: 1.3;
  }

  .footer {
    margin-top: 0;
  }
}
